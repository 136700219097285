import React, { useState, useEffect, useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import { addBookingRoom, selectBooking } from "../../features/booking/bookingSlice";
import { getRoomAvailability } from "../../helpers/availability";
import ReservationCard from "./ReservationCard";
import { GTMTrackingSelectAccommodation } from "../../helpers/GTMTracking";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { IRoomCategory } from "../../features/booking/bookingInterface";
import { findRoomCategoryByName } from "../../helpers/roomCategory";
import PlusSmall from "../Svg/PlusSmall";
import { useInView } from "framer-motion";

function Reservations() {
  const booking = useAppSelector(selectBooking);
  const dispatch = useDispatch();
  const reservations = booking.Reservations;
  const [trackSelectItem, setTrackSelectItem] = useState<boolean>(false);
  const addButtons = useRef<HTMLDivElement>(null);
  const [showStickyButton, setShowStickyButton] = useState<boolean>(false);
  const hubRoomCategory = findRoomCategoryByName("Hub");
  const twinHubRoomCategory = findRoomCategoryByName("Twin Hub");
  const videoHubRoomCategory = findRoomCategoryByName("Video Hub");
  const roomRoomCategory = findRoomCategoryByName("Room");
  const [numberOfRooms, setNumberOfRooms] = useState<number>(0);
  const isInView = useInView(addButtons, { amount: 0.8 });

  useEffect(() => {
    setShowStickyButton(!isInView);
  }, [isInView]);

  useEffect(() => {
    if (trackSelectItem) {
      // Only track last item
      GTMTrackingSelectAccommodation();
      setTrackSelectItem(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.Reservations]);

  useEffect(() => {
    let count: number = 0;
    if (hubRoomCategory !== undefined) {
      count += getRoomAvailability(hubRoomCategory["Id"]);
    }
    if (twinHubRoomCategory !== undefined) {
      count += getRoomAvailability(twinHubRoomCategory["Id"]);
    }
    if (videoHubRoomCategory !== undefined) {
      count += getRoomAvailability(videoHubRoomCategory["Id"]);
    }
    if (roomRoomCategory !== undefined) {
      count += getRoomAvailability(roomRoomCategory["Id"]);
    }
    setNumberOfRooms(count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addAnother = () => {
    if (hubRoomCategory !== undefined && getRoomAvailability(hubRoomCategory["Id"]) > 0) {
      addReservation(hubRoomCategory["Id"]);
    } else if (
      twinHubRoomCategory !== undefined &&
      getRoomAvailability(twinHubRoomCategory["Id"]) > 0
    ) {
      addReservation(twinHubRoomCategory["Id"]);
    } else if (
      videoHubRoomCategory !== undefined &&
      getRoomAvailability(videoHubRoomCategory["Id"]) > 0
    ) {
      addReservation(videoHubRoomCategory["Id"]);
    } else if (roomRoomCategory !== undefined && getRoomAvailability(roomRoomCategory["Id"]) > 0) {
      addReservation(roomRoomCategory["Id"]);
    }
  };

  const addReservation = (RoomCategoryId: IRoomCategory["RoomCategoryId"]) => {
    setTrackSelectItem(true);
    dispatch(
      addBookingRoom({
        RoomCategoryId,
        AdultCount: booking.AdultCount,
        ChildCount: booking.ChildCount,
        Open: true,
      })
    );
    setTimeout(() => {
      addButtons.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  return (
    <div className="relative">
      <div className="grid grid-cols-1 gap-4">
        {reservations.map((reservation, i) => {
          return <ReservationCard key={i} number={i} reservation={reservation} />;
        })}
      </div>

      <div ref={addButtons} className="py-4">
        <div className="pb-4 flex gap-2 justify-center items-center">
          {numberOfRooms > 0 && (
            <Button
              text="Add another accommodation"
              classes="secondary"
              size="medium"
              onClick={() => addAnother()}
              suffix={<PlusSmall />}
            />
          )}
          {numberOfRooms === 0 && (
            <div className="rounded-md flex items-center whitespace-nowrap border border-black text-black bg-white bg-opacity-50 p-2 w-full justify-between text-medium">
              <span className="leading-none flex-grow text-center ml-3">
                No more rooms available.
              </span>
            </div>
          )}
        </div>
      </div>

      {(showStickyButton && numberOfRooms > 0) && (
        <div className="bg-booking py-2 sticky w-full -bottom-4 left-0 z-50">
          <Button
            text="Add another accommodation"
            classes="secondary"
            size="medium"
            onClick={() => addAnother()}
            suffix={<PlusSmall />}
          />
        </div>
      )}
    </div>
  );
}

export default Reservations;
